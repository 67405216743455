import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import {
	lippFormValues,
	medicareLippFormValues,
} from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	ListItem,
	PdfCheckbox,
	PdfField,
	PdfFooter,
	pdfStyles,
	YesNoCheckbox,
} from "../../../components/PdfDownload/PdfTemplate";
import calculateAge from "../../../utils/calculateAge";
import { MedicalConditionOptions } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import incomeGuidelines from "../../../assets/Qualifying Income Guidelines 2024.png";
import incomeGuidelines2025 from "../../../assets/Qualifying Income Guidelines 2025.png";
import { newAdultIncomeSource } from "../Section4/AffidavitB";

const LIPPPdf = (props: {
	formValues: typeof lippFormValues | typeof medicareLippFormValues;
	isMedicare: boolean;
	showTimeStamp?: boolean;
}) => {
	const { formValues, isMedicare, showTimeStamp } = props;
	const heading = "Low Income Premium Program";
	const heading2 = isMedicare ? "Medicare Carve-Out" : "Non-Medicare";
	const revisionLabel = "Revision 06.27.24";

	return (
		<Document>
			<PdfPage
				heading={heading}
				heading2={heading2}
				pageLabel="Page 1 of 6"
				revisionLabel={revisionLabel}
				showTimeStamp={showTimeStamp}
			>
				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						The Low Income Premium Program (LIPP) is designed to help persons
						who qualify for NMMIP coverage remain on NMMIP coverage by offering
						a reduced premium.
						<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textUnderline }}>
							Income does not determine NMMIP eligibility
						</Text>
						. You must meet the eligibility requirements on the NMMIP
						application to be eligible for coverage. To find out if you should
						apply for LIPP, find your household size and the corresponding
						yearly income amount in the table below.{" "}
						<Text style={{ ...pdfStyles.fontBold }}>
							If your premium is paid by a third party who is not a family
							member, you are not eligible for LIPP.
						</Text>
					</Text>

					<Text>
						For assistance, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>{" "}
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<Text
					style={{
						...pdfStyles.fontBold,
						...pdfStyles.textCenter,
						...pdfStyles.mb5,
					}}
				>
					Qualifying Income Guidelines Effective{" "}
					<Text>
						{formValues["coverageStartYear"] === "2024"
							? "01/01/2024 - 12/31/2024"
							: "01/01/2025 - 12/31/2025"}
					</Text>
				</Text>
				{formValues["coverageStartYear"] === "2024" ? (
					<Image src={incomeGuidelines} />
				) : (
					<Image src={incomeGuidelines2025} />
				)}

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>Applicant Information</Text>
					<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.applicant.lastName}</Text>
					</PdfField>
					<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
						<Text>{formValues.applicant.firstName}</Text>
					</PdfField>
					<PdfField
						label="MI"
						style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.middleInitial}</Text>
					</PdfField>

					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w50 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w125 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{
							...pdfStyles.w125,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>

					<PdfField
						label="Home Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.applicant.homePhone}</Text>
					</PdfField>
					<PdfField
						label="Work Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.applicant.workPhone}</Text>
					</PdfField>
					<PdfField
						label="Cell Phone (include area code)"
						style={{ ...pdfStyles.wThird, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.applicant.cellPhone}</Text>
					</PdfField>
					<PdfField
						label="Email Address"
						style={{ ...pdfStyles.wTwoThirds, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.applicant.email}</Text>
					</PdfField>
					<PdfField
						label="NMMIP Member ID (if applicable)"
						style={{
							...pdfStyles.wThird,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
						}}
					>
						<Text>{formValues.applicant.nmmipID}</Text>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Premium Payment Certification
					</Text>
					<Text
						style={{
							...pdfStyles.fontBold,
						}}
					>
						I certify that I, or a member of my family, will be paying my NMMIP
						coverage premiums.
					</Text>

					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						NOTE: If your premium is being paid by a third party who is not a
						family member, you are not eligible for LIPP.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>

						{formValues.lippPaymentSignature ? (
							<Image
								style={fieldStyles.signature}
								src={formValues.lippPaymentSignature}
							/>
						) : (
							<></>
						)}
					</PdfField>
				</View>
			</PdfPage>

			<PdfPage
				heading={heading}
				heading2={heading2}
				pageLabel="Page 2 of 6"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Household Size & Income Verification
					</Text>

					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						To determine if you qualify for a reduced premium, provide
						information about your household size and last year's total combined
						income for all persons over age 18 in your household. Even if only
						one person is enrolled for NMMIP coverage, you must still provide
						information about the entire household, since the premium reduction
						eligibility is based on total household size and income.
					</Text>

					<Text
						style={{
							...pdfStyles.mb5,
						}}
					>
						List all the people in your household. Use additional sheets, if
						needed, for more household members.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
							...pdfStyles.bgGray,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
							}}
						>
							Name
						</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
							...pdfStyles.bgGray,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
							}}
						>
							Relationship
						</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.fieldSmall,
							...fieldStyles.borderRight,
							...pdfStyles.bgGray,
						}}
					>
						<Text
							style={{
								...pdfStyles.font10,
								...pdfStyles.fontBold,
							}}
						>
							Date of Birth
						</Text>
					</PdfField>

					{Array.from(Array(6).keys()).map((member, i) => (
						<>
							<PdfField
								key={i}
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...(i === 5 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>{formValues.household[i].name}</Text>
								) : (
									<></>
								)}
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...(i === 5 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>{formValues.household[i].relationship}</Text>
								) : (
									<></>
								)}
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.wThird,
									...fieldStyles.fieldSmall,
									...fieldStyles.borderRight,
									...(i === 5 && { ...fieldStyles.borderBottom }),
								}}
							>
								{formValues.household.length > i ? (
									<Text>
										{formValues.household[i].birthDate
											? displayFormattedDate(formValues.household[i].birthDate)
											: ""}
									</Text>
								) : (
									<></>
								)}
							</PdfField>
						</>
					))}
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Household Income Information and Verification
					</Text>

					{isMedicare ? (
						<>
							<Text style={{ ...pdfStyles.mb10 }}>
								<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.mb10 }}>
									NOTE:{" "}
								</Text>
								You do <Text style={{ ...pdfStyles.textUnderline }}>not</Text>{" "}
								need to include income information or verification for any
								member of your household whose income is from Supplementary
								Security Income (SSI) and/or Temporary Assistance for Needy
								Families (TANF){" "}
								<Text style={{ ...pdfStyles.fontBold }}>ONLY</Text>.
							</Text>

							<Text>
								List total annual income amount for adults in your household{" "}
								<Text style={{ ...pdfStyles.textUnderline }}>
									except as excluded above
								</Text>
							</Text>

							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-end",
									gap: 10,
									...pdfStyles.mb10,
								}}
							>
								<Text>(from Federal Tax Form: Line 7b of Form 1040)</Text>
								<View
									style={{
										...pdfStyles.w35,
										...pdfStyles.underline,
									}}
								>
									<Text>$</Text>
								</View>
							</View>
						</>
					) : (
						<>
							<Text style={{ ...pdfStyles.mb10 }}>
								For the verification process, NMMIP uses the Modified Adjusted
								Gross Income (MAGI) method to determine your eligibility for
								LIPP. MAGI is adjusted gross income (AGI) plus excluded foreign
								income, non-taxable Social Security benefits, and tax-exempt
								interest.
							</Text>
							<Text>
								Complete the table below including yourself and these members of
								your household:
							</Text>
							<View style={{ width: "90%", ...pdfStyles.mb10 }}>
								<ListItem>
									Include your spouse if you're legally married
								</ListItem>
								<ListItem>
									If you plan to claim someone as a tax dependent for the year
									you want NMMIP coverage, include them on your application. (If
									you won't claim that person as a tax dependent, do not include
									them.)
								</ListItem>
								<ListItem>
									Include your spouse and tax dependents even if they do not
									need health coverage
								</ListItem>
							</View>
							<Text style={{ ...pdfStyles.mb10 }}>
								For more information, please go to{" "}
								<Link
									style={pdfStyles.link}
									src="https://www.healthcare.gov/income-and-household-information"
								>
									www.healthcare.gov/income-and-household-information
								</Link>
								.
							</Text>

							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
								}}
							>
								<Text style={{ ...pdfStyles.textUnderline }}>
									Adjusted Gross Income (AGI)
								</Text>
								<Text>As defined by the IRS, AGI is gross income minus</Text>
								<Text>adjustments to income</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w30,
								}}
							>
								<Text>
									Enter the amount from your most recent 1040 tax form
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w20,
									...fieldStyles.borderRight,
								}}
							>
								<Text>
									${(formValues as typeof lippFormValues).adjustedGrossIncome}
								</Text>
							</PdfField>

							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
								}}
							>
								<Text style={{ ...pdfStyles.textUnderline }}>
									Excluded Foreign Income
								</Text>
								<Text>Foreign earned income excluded from taxation of</Text>
								<Text>individuals who live abroad.</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w30,
								}}
							>
								<Text>Enter the amount, if applicable</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w20,
									...fieldStyles.borderRight,
								}}
							>
								<Text>
									${(formValues as typeof lippFormValues).excludedForeignIncome}
								</Text>
							</PdfField>

							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
								}}
							>
								<Text style={{ ...pdfStyles.textUnderline }}>
									Non-Taxable Social Security Benefits
								</Text>
								<Text>
									Social Security benefits not included in gross income.
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w30,
								}}
							>
								<Text>
									Enter the amount from your most recent 1040 tax form
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w20,
									...fieldStyles.borderRight,
								}}
							>
								<Text>
									$
									{
										(formValues as typeof lippFormValues)
											.nonTaxableSocialSecurityBenefits
									}
								</Text>
							</PdfField>

							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
								}}
							>
								<Text style={{ ...pdfStyles.textUnderline }}>
									Tax-Exempt Interest
								</Text>
								<Text>
									Interest income that is not subject to federal income tax.
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w30,
								}}
							>
								<Text>
									Enter the amount from your most recent 1040 tax form
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w20,
									...fieldStyles.borderRight,
								}}
							>
								<Text>
									${(formValues as typeof lippFormValues).taxExemptInterest}
								</Text>
							</PdfField>

							<PdfField
								label=""
								style={{
									...pdfStyles.w80,
									...fieldStyles.borderBottom,
								}}
							>
								<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textRight }}>
									Add all amounts in the boxes above to total MAGI
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w20,
									...fieldStyles.borderBottom,
									...fieldStyles.borderRight,
								}}
							>
								<Text>
									$
									{(formValues as typeof lippFormValues)
										.modifiedAdjustedGrossIncome ? (
										(formValues as typeof lippFormValues)
											.modifiedAdjustedGrossIncome
									) : (
										<></>
									)}
								</Text>
							</PdfField>
						</>
					)}

					<Text style={{ ...pdfStyles.fontBold }}>In addition,</Text>
					<View style={{ width: "90%" }}>
						<ListItem listLabel="1.">
							Attach a copy of the previous year's Federal Income Tax forms
							filed (include certification form if filed electronically) by each
							household member who had income, except as excluded above, and
							complete and sign the{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								Affidavit A - Federal Tax Form Affidavit
							</Text>{" "}
							portion of this application{" "}
							<Text style={{ ...pdfStyles.fontBold }}>- AND/OR -</Text>
						</ListItem>
						<ListItem listLabel="2.">
							If any adult in your household had income, except as excluded
							above, but were not required to file a Federal Income Tax form,
							they must complete, sign and{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								notarize the Affidavit B - Other Income Source Affidavit
							</Text>{" "}
							portion of this application.
						</ListItem>
					</View>
				</View>
			</PdfPage>

			{/* Affidavit A */}
			<PdfPage
				heading={heading}
				heading2={heading2}
				pageLabel="Page 3 of 6"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Federal Tax Form and Affidavit A
					</Text>

					<Text style={{ ...pdfStyles.mb10 }}>
						Attach a copy of the prior year's filed Federal Income Tax forms,
						including certification form if filed electronically, for each
						household member who had income (except as excluded above) AND
						complete and sign{" "}
						<Text style={{ ...pdfStyles.fontBold }}>Affidavit A:</Text>
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<Text>
							By my signature, I swear or affirm that the attached tax form is a
							true reflection of my income for calendar year 20____, and is a
							correct copy of the form provided to the Internal Revenue Service
							(IRS). I certify that the foregoing answers are true and accurate
							to the best of my knowledge and belief. I also acknowledge that
							NMMIP may verify this information with state agencies and other
							sources.
						</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w35,
							minHeight: 75,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Print Name of Applicant
						</Text>
						<Text>{formValues.affidavitA.applicantName}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							minHeight: 75,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>
						<Text
							style={{
								...pdfStyles.font10,
							}}
						>
							Or parent/legal guardian/personal representative if applicant is
							under 18 years of age or legally incompetent.
						</Text>
						{formValues.affidavitA.signature ? (
							<Image
								style={fieldStyles.signature}
								src={formValues.affidavitA.signature}
							/>
						) : (
							<></>
						)}
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w15,
							...fieldStyles.borderRight,
							minHeight: 75,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Today's Date
						</Text>
						<Text>
							{displayFormattedDate(formValues.affidavitA.todaysDate)}
						</Text>
					</PdfField>

					<PdfField
						label="PRINTED NAME of parent/legal guardian/personal representative"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitA.legalGuardian}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Relationship to Applicant
						</Text>
						{/* <Text
							style={{
								...pdfStyles.font8,
							}}
						>
							Attach legal document if other than Parent.
						</Text> */}
						<Text>{formValues.affidavitA.legalGuardianRelationship}</Text>
					</PdfField>
				</View>
			</PdfPage>

			{/* Affidavit B*/}
			{(formValues.affidavitB.length > 0
				? formValues.affidavitB
				: [newAdultIncomeSource]
			).map((householdMember, i) => (
				<PdfPage
					key={i}
					heading={heading}
					heading2={heading2}
					pageLabel="Page 4 of 6"
					revisionLabel={revisionLabel}
				>
					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<Text style={{ ...fieldStyles.heading }}>
							Other Income Source Affidavit B
						</Text>

						<Text>
							If any adult in your household had income (except as excluded
							above) and was not required to file a Federal Income Tax form,
							they must complete and sign{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								Other Income Source Affidavit B
							</Text>{" "}
							below. Attach additional copies if needed.
						</Text>

						{/* income source table */}
						<View
							style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}
						>
							<PdfField
								label=""
								style={{ ...pdfStyles.wFull, ...fieldStyles.borderRight }}
							>
								<Text>
									By my signature, I swear or affirm that I am not required to
									file a Federal Income Tax return for calendar year 20____, and
									that my income for that calendar year was as noted below.
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...pdfStyles.bgGray,
								}}
							>
								<Text
									style={{
										...pdfStyles.font10,
										...pdfStyles.fontBold,
									}}
								>
									Income Source Description
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...fieldStyles.borderRight,
									...pdfStyles.bgGray,
								}}
							>
								<Text
									style={{
										...pdfStyles.font10,
										...pdfStyles.fontBold,
									}}
								>
									Income Amount
								</Text>
							</PdfField>
							{Array.from(Array(3).keys()).map((income, i) => (
								<>
									<PdfField
										key={i}
										label=""
										style={{
											...pdfStyles.w50,
										}}
									>
										{householdMember.incomeSources.length > i ? (
											<Text>
												{householdMember.incomeSources[i].description}
											</Text>
										) : (
											<></>
										)}
									</PdfField>
									<PdfField
										label=""
										style={{
											...pdfStyles.w50,

											...fieldStyles.borderRight,
										}}
									>
										{householdMember.incomeSources.length > i ? (
											<Text>${householdMember.incomeSources[i].amount}</Text>
										) : (
											<Text>$</Text>
										)}
									</PdfField>
								</>
							))}
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
								}}
							>
								<Text style={{ ...pdfStyles.fontBold, ...pdfStyles.textRight }}>
									Total:
								</Text>
							</PdfField>
							<PdfField
								label=""
								style={{
									...pdfStyles.w50,
									...fieldStyles.fieldSmall,
									...pdfStyles.bgGray,
									...fieldStyles.borderRight,
								}}
							>
								<Text>$</Text>
							</PdfField>

							<PdfField label="Last Name" style={{ ...pdfStyles.w50 }}>
								<Text>{householdMember.lastName}</Text>
							</PdfField>
							<PdfField label="First Name" style={{ ...pdfStyles.w40 }}>
								<Text>{householdMember.firstName}</Text>
							</PdfField>
							<PdfField
								label="MI"
								style={{ ...pdfStyles.w10, ...fieldStyles.borderRight }}
							>
								<Text>{householdMember.middleInitial}</Text>
							</PdfField>

							{/* contact info */}
							<PdfField
								label="Home Phone"
								style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
							>
								<Text>{householdMember.homePhone}</Text>
							</PdfField>
							<PdfField
								label="Work Phone"
								style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
							>
								<Text>{householdMember.workPhone}</Text>
							</PdfField>
							<PdfField
								label="Cell Phone"
								style={{ ...pdfStyles.w20, ...fieldStyles.borderBottom }}
							>
								<Text>{householdMember.cellPhone}</Text>
							</PdfField>
							<PdfField
								label="Email Address"
								style={{
									...pdfStyles.w40,
									...fieldStyles.borderRight,
									...fieldStyles.borderBottom,
								}}
							>
								<Text>{householdMember.email}</Text>
							</PdfField>
						</View>

						<PdfField
							label=""
							style={{
								...pdfStyles.w50,
								...fieldStyles.borderBottom,
								minHeight: 50,
								gap: 0,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Signature
							</Text>

							{householdMember.signature ? (
								<Image
									style={fieldStyles.signature}
									src={householdMember.signature}
								/>
							) : (
								<></>
							)}
						</PdfField>

						<PdfField
							label=""
							style={{
								...pdfStyles.w30,
								minHeight: 50,
								...fieldStyles.borderBottom,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Printed Name of Applicant
							</Text>
							<Text>{householdMember.applicantName}</Text>
						</PdfField>

						<PdfField
							label=""
							style={{
								...pdfStyles.w20,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
								minHeight: 50,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Today's Date
							</Text>
							{householdMember.todaysDate ? (
								displayFormattedDate(householdMember.todaysDate)
							) : (
								<></>
							)}
						</PdfField>

						{/* notary section */}
						<View style={{ marginTop: 50 }}>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-start",
									gap: 10,
									marginBottom: 15,
								}}
							>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									Subscribed and sworn before me this
								</Text>
								<View
									style={{
										...pdfStyles.w5,
										...pdfStyles.underline,
										...pdfStyles.mb12,
									}}
								></View>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									day of
								</Text>
								<View
									style={{
										...pdfStyles.w15,
										...pdfStyles.underline,
										...pdfStyles.mb12,
									}}
								></View>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									by
								</Text>
								<View
									style={{
										...pdfStyles.w30,
									}}
								>
									<View
										style={{
											...pdfStyles.underline,
										}}
									></View>
									<Text
										style={{
											...pdfStyles.textCenter,
										}}
									>
										(Name)
									</Text>
								</View>
							</View>

							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.wFull,
									alignItems: "flex-start",
									gap: 10,
								}}
							>
								<Text
									style={{
										position: "relative",
										top: -12,
									}}
								>
									My commission expires:
								</Text>
								<View
									style={{
										...pdfStyles.w25,
										...pdfStyles.underline,
									}}
								></View>
								<View
									style={{
										...pdfStyles.w50,
									}}
								>
									<View
										style={{
											...pdfStyles.underline,
										}}
									></View>
									<Text
										style={{
											...pdfStyles.textCenter,
										}}
									>
										Notary Public
									</Text>
								</View>
							</View>
						</View>
					</View>
				</PdfPage>
			))}

			{/* Affidavit C */}
			<PdfPage
				heading={heading}
				heading2={heading2}
				pageLabel="Page 5 of 6"
				revisionLabel={revisionLabel}
			>
				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Income Change Affidavit C
					</Text>

					<Text
						style={{
							...pdfStyles.mb10,
						}}
					>
						If your current income is different from your most recent tax
						filing, submit a copy of your Federal Income Tax return AND complete{" "}
						<Text style={{ ...pdfStyles.fontBold }}>
							Income Change Affidavit C
						</Text>{" "}
						for eligibility consideration according to your{" "}
						<Text style={{ ...pdfStyles.textUnderline }}>current</Text>{" "}
						household income.
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
						}}
					>
						<Text>
							By my signature, I swear or affirm that my current income for
							calendar year 20____ is as noted below.
						</Text>
					</PdfField>

					<PdfField
						label="Current Annual Household Income Total:"
						style={{
							...pdfStyles.w50,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitC.currentAnnualHouseholdIncome}</Text>
					</PdfField>
					<PdfField
						label="Reason for Difference between most recent Tax Filing and Current Household Income:"
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text>{formValues.affidavitC.explanationForIncomeDifference}</Text>
					</PdfField>

					<PdfField
						label=""
						style={{
							...pdfStyles.wThird,
							...fieldStyles.borderBottom,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Print Name of Applicant
						</Text>
						<Text>{formValues.affidavitC.applicantName}</Text>
					</PdfField>
					<PdfField
						label=""
						style={{
							...pdfStyles.wTwoThirds,
							...fieldStyles.borderBottom,
							...fieldStyles.borderRight,
							minHeight: 50,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of Applicant
						</Text>

						{formValues.affidavitC.signature ? (
							<Image
								style={fieldStyles.signature}
								src={formValues.affidavitC.signature}
							/>
						) : (
							<></>
						)}
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section }}>
					<View style={{ ...pdfStyles.contactInfo }}>
						<Text>New Mexico Medical Insurance Pool (NMMIP)</Text>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Mail:</Text>
							<Text>P.O. Box 780548, San Antonio, Tx 78278</Text>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Email:</Text>
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
						</View>

						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap5 }}>
							<Text style={{ ...pdfStyles.fontBold }}>Fax:</Text>{" "}
							<Text>210-239-8449</Text>
						</View>
					</View>
				</View>
			</PdfPage>

			<PdfFooter
				heading={heading}
				heading2={heading2}
				pageLabel="Page 6 of 6"
				revisionLabel={revisionLabel}
			/>
		</Document>
	);
};

export default LIPPPdf;
