import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	DocumentProps,
	Link,
	Image,
} from "@react-pdf/renderer";
import { newbornFormValues } from "../FormSchema/formInitialValues";
import PdfPage, {
	displayFormattedDate,
	fieldStyles,
	PdfCheckbox,
	PdfField,
	PdfFooter,
	pdfStyles,
} from "../../../components/PdfDownload/PdfTemplate";
import {
	BankAccountTypes,
	DebitWithdrawlDate,
	PaymentTypes,
} from "../../HealthCareCoverageForm/FormSchema/formInitialValues";

export const newbornPdfStyles = StyleSheet.create({
	certification: {
		paddingHorizontal: 30,
	},
	signatureLabel: {
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
		textAlign: "center",
		marginTop: 50,
		paddingHorizontal: 30,
	},
});

const NewbornPdf = (props: {
	formValues: typeof newbornFormValues;
	showTimeStamp?: boolean;
}) => {
	const { formValues, showTimeStamp } = props;
	const heading = "Application for Coverage";
	const heading2 = "Newborn";
	const revisionLabel = "Revision 08.26.24";

	return (
		<Document>
			<PdfPage
				heading={heading}
				heading2={heading2}
				pageLabel="Page 1 of 2"
				revisionLabel={revisionLabel}
				showTimeStamp={showTimeStamp}
			>
				{/* <View
				style={{
					...pdfStyles.subHeader,
					...pdfStyles.section,
				}}
			>
				<Text style={{ ...pdfStyles.maxW70 }}>
					Newborn coverage for the first 31 days after birth is a benefit of the
					parent's Pool policy. Only current NMMIP Enrollees are eligible for
					newborn coverage. At day 32 from the date of birth, the child's policy
					will be canceled, unless there is a qualifying event which would make
					the child eligible for NMMIP. First month's premium must be
					included with the application.
				</Text>

				<View style={{ ...pdfStyles.address }}>
					<Text>P.O. Box 780548</Text>
					<Text>San Antonio, Tx 78278</Text>
					<Text>1-866-306-1882</Text>
					<Link style={pdfStyles.address} src="https://nmmip.org/">
						www.nmmip.org
					</Link>
				</View>
			</View> */}

				<View style={{ ...pdfStyles.section }}>
					<Text style={{ ...pdfStyles.wFull, ...pdfStyles.mb10 }}>
						Newborn coverage for the first 31 days after birth is a benefit of
						the parent's NMMIP policy. Only current NMMIP Enrollees are eligible
						for newborn coverage. At day 32 from the date of birth, the child's
						policy will be canceled, unless there is a qualifying event which
						would make the child eligible for NMMIP. First month's premium must
						be included with the application.
					</Text>

					<Text>
						For assistance, please contact{" "}
						<Link style={pdfStyles.link} src="tel:+18663061882">
							1-866-306-1882
						</Link>
					</Text>
					<Text>
						or email{" "}
						<Link
							style={pdfStyles.link}
							src="mailto:NMMIP_Eligibility@90degreebenefits.com"
						>
							NMMIP_Eligibility@90degreebenefits.com
						</Link>
						.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>Applicant Information</Text>

					{/* Newborn info */}
					<PdfField label="Newborn's First Name" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.newborn.firstName}</Text>
					</PdfField>
					<PdfField label="Newborn's Last Name" style={{ ...pdfStyles.w20 }}>
						<Text>{formValues.newborn.lastName}</Text>
					</PdfField>
					<PdfField label="MI" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.newborn.middleInitial}</Text>
					</PdfField>
					<PdfField label="Gender" style={{ ...pdfStyles.w25 }}>
						<View style={{ ...pdfStyles.flexCol }}>
							<PdfCheckbox
								label="Male"
								isChecked={formValues.newborn.gender === "Male"}
							/>
							<PdfCheckbox
								label="Female"
								isChecked={formValues.newborn.gender === "Female"}
							/>
						</View>
					</PdfField>
					<PdfField
						label="Birth Date (MM/DD/YYYY)"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
					>
						{displayFormattedDate(formValues.newborn.birthDate)}
					</PdfField>
					{/* NMMIP Enrollee */}
					<PdfField
						label="NMMIP Enrollee's Last Name"
						style={{ ...pdfStyles.w20 }}
					>
						<Text>{formValues.enrollee.lastName}</Text>
					</PdfField>
					<PdfField
						label="NMMIP Enrollee's First Name"
						style={{ ...pdfStyles.w20 }}
					>
						<Text>{formValues.enrollee.firstName}</Text>
					</PdfField>
					<PdfField label="MI" style={{ ...pdfStyles.w10 }}>
						<Text>{formValues.enrollee.middleInitial}</Text>
					</PdfField>
					<PdfField
						label="NMMIP Enrollee's NMMIP ID Number"
						style={{ ...pdfStyles.w25 }}
					>
						<Text>{formValues.enrollee.nmmipID}</Text>
					</PdfField>
					<PdfField
						label="NMMIP Enrollee's Birth Date"
						style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
					>
						{displayFormattedDate(formValues.enrollee.birthDate)}
					</PdfField>
					{/* residence address */}
					<PdfField
						label="Residence Address (Physical address required)"
						style={{ ...pdfStyles.w50 }}
					>
						<Text>{formValues.residence.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.residence.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.residence.zipCode}</Text>
					</PdfField>
					{/* mailing address */}
					<PdfField label="Mailing Address" style={{ ...pdfStyles.w50 }}>
						<Text>{formValues.mailing.address}</Text>
					</PdfField>
					<PdfField label="City" style={{ ...pdfStyles.w25 }}>
						<Text>{formValues.mailing.city}</Text>
					</PdfField>
					<PdfField label="State" style={{ ...pdfStyles.w10 }}>
						<Text>NM</Text>
					</PdfField>
					<PdfField
						label="Zip"
						style={{ ...pdfStyles.w15, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.mailing.zipCode}</Text>
					</PdfField>
					<PdfField
						label="Home Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.enrollee.homePhone}</Text>
					</PdfField>
					<PdfField
						label="Work Phone (include area code)"
						style={{ ...pdfStyles.wThird }}
					>
						<Text>{formValues.enrollee.workPhone}</Text>
					</PdfField>
					<PdfField
						label="Cell Phone (include area code)"
						style={{ ...pdfStyles.wThird, ...fieldStyles.borderRight }}
					>
						<Text>{formValues.enrollee.cellPhone}</Text>
					</PdfField>
					<PdfField
						label="Email Address"
						style={{ ...pdfStyles.wThird, ...fieldStyles.borderBottom }}
					>
						<Text>{formValues.enrollee.email}</Text>
					</PdfField>
					<PdfField
						label="The newborn will be a resident of the state of New Mexico."
						style={{
							...pdfStyles.wTwoThirds,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
						}}
					>
						<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
							<PdfCheckbox
								label="Yes"
								isChecked={formValues.isNewbornResidentOfNM === "Yes"}
							/>
							<PdfCheckbox
								label="No"
								isChecked={formValues.isNewbornResidentOfNM === "No"}
							/>
						</View>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<Text style={{ ...fieldStyles.heading }}>
						Premium Payment Information
					</Text>

					<PdfField
						label=""
						style={{
							...pdfStyles.wFull,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
							minHeight: 100,
						}}
					>
						<View
							style={{
								...pdfStyles.flexCol,
								...pdfStyles.gap10,
								...pdfStyles.flex1,
							}}
						>
							<Text>
								Select the method of payment for your initial premium (must be
								included for coverage consideration).
							</Text>
							<View
								style={{
									...pdfStyles.flexRow,
									...pdfStyles.flex1,
									...pdfStyles.fontBold,
									...pdfStyles.wFull,
									alignItems: "flex-end",
								}}
							>
								<Text>Amount $</Text>

								<View
									style={{
										...pdfStyles.w10,
										...pdfStyles.underline,
										...pdfStyles.ml3,
									}}
								>
									<Text>{formValues.paymentAmount}</Text>
								</View>
							</View>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								{PaymentTypes.map((type) => (
									<PdfCheckbox
										label={type.label}
										isChecked={formValues.paymentType === type.label}
									/>
								))}
							</View>
							<Text>
								For one-time or monthly ACH, complete and attach Agreement for
								Preauthorized Payments (ACH Form). If paying first premium by
								check or money order, you must MAIL the application and all
								attachments WITH the payment.
							</Text>
						</View>
					</PdfField>
				</View>

				<View style={{ ...pdfStyles.section, ...pdfStyles.font8 }}>
					<Text
						style={{
							...pdfStyles.wFull,
						}}
					>
						I certify that the foregoing statements are true and accurate. I
						understand that no coverage will be effective until the full initial
						premium is paid and this application has been approved by NMMIP
						Administrator. I understand that this policy will automatically be
						canceled 31 days after the newborn's birth unless the newborn has a
						qualifying event which makes him/her eligible for NMMIP. I further
						understand that this coverage is a benefit of my policy with NMMIP,
						and I certify that I am the biological or adoptive parent or legal
						guardian of this child.
					</Text>
				</View>

				<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
					<PdfField
						label=""
						style={{
							...pdfStyles.w50,
							...fieldStyles.borderBottom,
							minHeight: 50,
							gap: 0,
						}}
					>
						<Text
							style={{
								...pdfStyles.fontBold,
								...pdfStyles.font10,
							}}
						>
							Signature of NMMIP Enrollee
						</Text>

						{formValues.affirmation.NMMIPenrolleeSignature && (
							<Image
								style={fieldStyles.signature}
								src={formValues.affirmation.NMMIPenrolleeSignature}
							/>
						)}
					</PdfField>
					<PdfField
						label="Relationship to Newborn"
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderBottom,
						}}
					>
						<Text>{formValues.affirmation.relationshipToNewborn}</Text>
					</PdfField>
					<PdfField
						label="Today's Date"
						style={{
							...pdfStyles.w25,
							...fieldStyles.borderRight,
							...fieldStyles.borderBottom,
							minHeight: 50,
						}}
					>
						<Text>
							{displayFormattedDate(formValues.affirmation.todaysDate)}
						</Text>
					</PdfField>
				</View>

				<View
					style={{
						...pdfStyles.section,
						...pdfStyles.wFull,
						...pdfStyles.flexRow,
						...pdfStyles.mt10,
						...pdfStyles.alignStart,
					}}
				>
					<View
						style={{
							...pdfStyles.w50,
							...pdfStyles.textCenter,
						}}
					>
						<Text style={pdfStyles.fontSemiBold}>
							State Pool requires first month's premium to be paid.
						</Text>
						<Text style={pdfStyles.fontSemiBold}>Make check payable to:</Text>
						<Text>New Mexico Medical Insurance Pool</Text>
					</View>
					<View
						style={{
							...pdfStyles.w50,
							...pdfStyles.textCenter,
						}}
					>
						<Text style={pdfStyles.fontSemiBold}>
							Mail complete application and premium check to:
						</Text>

						<Text>New Mexico Medical Insurance Pool</Text>
						<Text>P.O. Box 780548</Text>
						<Text>San Antonio, Tx 78278</Text>
					</View>
				</View>
			</PdfPage>

			{formValues["paymentType"] === PaymentTypes[1].label ||
			formValues["paymentType"] === PaymentTypes[2].label ? (
				<PdfPage
					heading="Agreement for Preauthorized"
					heading2="Payments"
					pageLabel="Page 1 of 1"
					revisionLabel={revisionLabel}
				>
					<View style={{ ...pdfStyles.section }}>
						<Text style={{ ...pdfStyles.wFull }}>
							I hereby authorize the New Mexico Medical Insurance Pool (NMMIP)
							to initiate debit entries from my account and Depository
							designated below. Pursuant to my election, debits will be drawn on
							the first or fifteenth of each month unless the date falls on a
							holiday, then it will be drawn the next business day.{" "}
							<Text style={{ ...pdfStyles.fontBold }}>
								Your account will be drafted for the month in which you are due
							</Text>{" "}
							(e.g., the January amount drafted is for your January coverage).
						</Text>
					</View>

					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<PdfField label="Last Name" style={{ ...pdfStyles.w40 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.lastName}</Text>
						</PdfField>
						<PdfField label="First Name" style={{ ...pdfStyles.w30 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.firstName}</Text>
						</PdfField>

						<PdfField
							label="NMMIP Member ID"
							style={{ ...pdfStyles.w30, ...fieldStyles.borderRight }}
						>
							<Text>{formValues.enrollee.nmmipID}</Text>
						</PdfField>
						<PdfField
							label="I elect to have funds withdrawn from my account on:"
							style={{ ...pdfStyles.w75 }}
						>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								<PdfCheckbox
									label={DebitWithdrawlDate[0].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.withdrawlDate ===
										DebitWithdrawlDate[0].label
									}
								/>
								<PdfCheckbox
									label={DebitWithdrawlDate[1].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.withdrawlDate ===
										DebitWithdrawlDate[1].label
									}
								/>
							</View>
						</PdfField>
						<PdfField
							label="Type of account:"
							style={{ ...pdfStyles.w25, ...fieldStyles.borderRight }}
						>
							<View style={{ ...pdfStyles.flexRow, ...pdfStyles.gap10 }}>
								<PdfCheckbox
									label={BankAccountTypes[0].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.accountType ===
										BankAccountTypes[0].label
									}
								/>
								<PdfCheckbox
									label={BankAccountTypes[1].label}
									isChecked={
										formValues.preAuthorizedPaymentInfo.accountType ===
										BankAccountTypes[1].label
									}
								/>
							</View>
						</PdfField>

						<PdfField
							label="Name of Financial Institution/Bank (Depository)"
							style={{ ...pdfStyles.w50 }}
						>
							<Text>{formValues.preAuthorizedPaymentInfo.bank}</Text>
						</PdfField>
						<PdfField label="City" style={{ ...pdfStyles.w20 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.city}</Text>
						</PdfField>
						<PdfField label="State" style={{ ...pdfStyles.w10 }}>
							<Text>{formValues.preAuthorizedPaymentInfo.state}</Text>
						</PdfField>
						<PdfField
							label="Zip"
							style={{
								...pdfStyles.w20,
								...fieldStyles.borderRight,
							}}
						>
							<Text>{formValues.preAuthorizedPaymentInfo.zipCode}</Text>
						</PdfField>
						<PdfField
							label="Routing Number"
							style={{ ...pdfStyles.w50, ...fieldStyles.borderBottom }}
						>
							<Text>
								{formValues.preAuthorizedPaymentInfo.bankRoutingNumber}
							</Text>
						</PdfField>
						<PdfField
							label="Account Number"
							style={{
								...pdfStyles.w50,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
							}}
						>
							<Text>
								{formValues.preAuthorizedPaymentInfo.bankAccountNumber}
							</Text>
						</PdfField>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.voidedCheck,
						}}
					>
						<Text style={{ ...fieldStyles.fieldHeader }}>
							Attach a Voided Check
						</Text>
						<Text>A deposit ticket will not be accepted</Text>
					</View>

					<View style={{ ...pdfStyles.section }}>
						<Text style={{ ...pdfStyles.wFull }}>
							This authority is to remain in full force and effect until NMMIP
							and Depository have received written notification from me of this
							agreement's termination in such time and in such manner as to
							afford NMMIP and the Depository reasonable opportunity to act upon
							the request.
						</Text>
					</View>

					<View style={{ ...pdfStyles.section, ...fieldStyles.fieldContainer }}>
						<PdfField
							label=""
							style={{
								...pdfStyles.w75,

								minHeight: 50,
								gap: 0,
							}}
						>
							<Text
								style={{
									...pdfStyles.fontBold,
									...pdfStyles.font10,
								}}
							>
								Signature
							</Text>

							{formValues.preAuthorizedPaymentInfo.signature && (
								<Image
									style={fieldStyles.signature}
									src={formValues.preAuthorizedPaymentInfo.signature}
								/>
							)}
						</PdfField>

						<PdfField
							label="Today's Date"
							style={{
								...pdfStyles.w25,
								...fieldStyles.borderRight,

								minHeight: 50,
							}}
						>
							<Text>
								{displayFormattedDate(
									formValues.preAuthorizedPaymentInfo.todaysDate
								)}
							</Text>
						</PdfField>
						<PdfField
							label=""
							style={{
								...pdfStyles.wFull,
								...fieldStyles.borderRight,
								...fieldStyles.borderBottom,
							}}
						>
							<Text
								style={{
									...pdfStyles.wFull,
									...pdfStyles.fontBold,
								}}
							>
								Signature must be from a person who has authority to sign on the
								account to be drafted.
							</Text>
						</PdfField>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.mt10,
							...pdfStyles.mxAuto,
							...pdfStyles.w50,
						}}
					>
						<View style={{ ...pdfStyles.textCenter }}>
							<Text>Submit this form by mail, email, or fax:</Text>
							<Text style={pdfStyles.fontBold}>
								New Mexico Medical Insurance Pool (NMMIP)
							</Text>
							<Text>Mail: P.O. Box 780548, San Antonio, Tx 78278</Text>
							<Text>
								Email:{" "}
								<Link
									style={pdfStyles.link}
									src="mailto:NMMIP_Eligibility@90degreebenefits.com"
								>
									NMMIP_Eligibility@90degreebenefits.com
								</Link>
							</Text>
							<Text>Fax: 210-239-8449</Text>
						</View>
					</View>

					<View
						style={{
							...pdfStyles.section,
							...pdfStyles.mt10,
							...pdfStyles.mxAuto,
						}}
					>
						<Text>
							For questions, please contact{" "}
							<Link style={pdfStyles.link} src="tel:+18663061882">
								1-866-306-1882
							</Link>{" "}
							or email{" "}
							<Link
								style={pdfStyles.link}
								src="mailto:NMMIP_Eligibility@90degreebenefits.com"
							>
								NMMIP_Eligibility@90degreebenefits.com
							</Link>
							.
						</Text>
					</View>
				</PdfPage>
			) : (
				<></>
			)}

			<PdfFooter
				heading={heading}
				heading2={heading2}
				pageLabel="Page 1 of 2"
				revisionLabel={revisionLabel}
			/>
		</Document>
	);
};

export default NewbornPdf;
