import { FormikProvider, useFormik, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import Button, { ColorStyle } from "../../../components/Button/Button";
import {
	medicareFormValues,
	medicareFormSchema,
	schemas,
} from "./formInitialValues";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { steps, renderFormStep } from "./Steps";
import { borders } from "../../../theme";
import { objectToArray } from "../../../utils/objectToArray";
import { FaExclamationTriangle } from "react-icons/fa";
import BrokerPopup from "../../../components/BrokerPopup/BrokerPopup";
import ProgressIndicator from "../../../components/ProgressIndictaor/ProgressIndicator";
import Modal from "../../../components/Modal/Modal";
import NoticeBanner from "../../../components/NoticeBanner/NoticeBanner";
import { PaymentTypes } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import { useLocation, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import DownloadPdfButton from "../../../components/PdfDownload/DownloadPdfButton";
import MedicarePdf from "../Pdf/MedicarePdf";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { pdfStyles } from "../../../components/PdfDownload/PdfTemplate";
import axios from "axios";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { saveAs } from "file-saver";
import { blobToPdf } from "../../../utils/blobToPdf";

export const FormSeparator = styled.hr`
	height: 5px;
	width: 100%;
	background: linear-gradient(90deg, #df7285, #e68d9d);
	/* margin: 0 0 30px 0; */
	border-radius: 5px;
`;

export const ErrorSummary = styled.div`
	${(p) => css`
		display: flex;
		flex-direction: column;
		background: #ffeff2;
		width: 100%;
		padding: 24px;
		gap: 10px;
		border-radius: ${borders.fields.radius};
		border: solid 2px ${p.theme.colorSecondary};
		color: ${p.theme.colorSecondary};

		h2 {
			color: ${p.theme.colorSecondary} !important;
		}
	`};
`;

const MedicareForm = () => {
	const [activeStep, setActiveStep] = useState(0);
	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 2;
	const isSubmissionPage = activeStep === steps.length - 1;
	const [showErrors, setShowErrors] = useState(false);
	const scrollRef = useRef<any>(null);
	const navigate = useNavigate();
	const [isMoneyOrderModalOpen, setIsMoneyOrderModalOpen] = useState(false);
	const { state } = useLocation();
	const downloadLinkContainer = document.querySelector(
		"#download-link-container"
	);
	const { formId } = useFormContext();
	const [loading, setLoading] = useState(true);
	const [initialFormValues, setInitialFormValues] =
		useState(medicareFormValues);
	const [downloadPdf, setDownloadPdf] = useState(false); // state to trigger PDF download
	const pdfName = "medicare_application.pdf";

	// Fetch existing form data and merge it with initial values
	useEffect(() => {
		const fetchMedicareData = async () => {
			try {
				const response = await axios.get(`/api/forms/MedicareForm/${formId}`); // Fetch existing Medicare form data
				const fetchedData = response.data;
				// Flatten and merge the fetched data
				const flattenedFetchedData = flattenFetchedData(fetchedData);
				const mergedValues = {
					...medicareFormValues,
					...flattenedFetchedData,
				};
				// Reset form values
				formikProps.resetForm({ values: mergedValues });
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch Medicare form data:", error);
				setLoading(false);
			}
		};
		if (formId) {
			fetchMedicareData();
		} else {
			setLoading(false);
		}
	}, [formId]);

	// Function to flatten nested data (reuse this from LIPP logic)
	const flattenFetchedData = (fetchedData: any) => {
		let flattenedData: any = {};
		Object.keys(fetchedData).forEach((schemaKey) => {
			const schemaData = fetchedData[schemaKey];
			if (typeof schemaData === "object" && !Array.isArray(schemaData)) {
				Object.keys(schemaData).forEach((innerKey) => {
					if (typeof schemaData[innerKey] === "object") {
						flattenedData = { ...flattenedData, ...schemaData };
					} else {
						flattenedData[innerKey] = schemaData[innerKey];
					}
				});
			} else {
				flattenedData = { ...flattenedData, ...schemaData };
			}
		});
		return flattenedData;
	};

	// Function to get the relevant section of form data based on the current step
	const getFormSectionByStep = (step: number, values: any) => {
		const stepName = Object.keys(schemas)[step - 1]; // Get the schema name for the current step

		const schema = schemas[stepName as keyof typeof schemas]; // Find the schema for this step
		if (schema && schema.values) {
			const sectionValues: Record<string, any> = {};

			// Extract only the fields that are part of this schema step
			for (const key in schema.values) {
				if (Object.prototype.hasOwnProperty.call(schema.values, key)) {
					sectionValues[key] = values[key]; // Grab the values from formikProps.values
				}
			}

			return { [stepName]: sectionValues }; // Return the section data under the schema name
		}

		return {}; // Return an empty object if no schema is found
	};

	// Save the current section of the form based on the active step
	const handleSaveForm = async (values: any) => {
		const formSection = getFormSectionByStep(activeStep, values);

		try {
			await axios.post(`/api/forms/update/MedicareForm/${formId}`, formSection); // Save the current form section
		} catch (error) {
			console.error("Failed to save form data:", error);
		}
	};

	const formikProps = useFormik({
		initialValues: medicareFormValues,
		validationSchema: medicareFormSchema[activeStep],
		validateOnChange: true,
		onSubmit: async (values, actions) => {
			await handleSaveForm(values); // Save form data
			if (isLastStep) {
				// TODO: api call to save the form

				formikProps.setSubmitting(true);
				handlePdfGenerated(); // generate PDF after form is submitted

				if (formikProps.values.applyingForLipp === "Yes") {
					handleRedirectToLipp();
				} else setActiveStep(activeStep + 1);
			} else if (activeStep === 7) {
				// skip section 4 if applicant is not insurance agent
				values.isInsuranceAgent === "Yes"
					? setActiveStep(activeStep + 1)
					: setActiveStep(activeStep + 2);
			} else {
				if (
					activeStep === 8 &&
					formikProps.values.paymentType === PaymentTypes[0].label
				) {
					setIsMoneyOrderModalOpen(true);
				}

				if (activeStep === 5) {
					// skip insurance agent section if applicant is not insurance agent
					values.isInsuranceAgent === "Yes"
						? setActiveStep(activeStep + 1)
						: setActiveStep(activeStep + 2);
				} else setActiveStep(activeStep + 1);

				actions.setTouched({});
				actions.setSubmitting(false);
			}

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	});

	const handleRedirectToLipp = () => {
		navigate("/lipp-form/medicare-carve-out", {
			state: {
				basicApp: {
					applicant: {
						firstName: formikProps.values.applicant.firstName,
						lastName: formikProps.values.applicant.lastName,
						middleInitial: formikProps.values.applicant.middleInitial,
						homePhone: formikProps.values.applicant.homePhone,
						workPhone: formikProps.values.applicant.workPhone,
						cellPhone: formikProps.values.applicant.cellPhone,
						email: formikProps.values.applicant.email,
					},
					residence: {
						address: formikProps.values.residence.address,
						city: formikProps.values.residence.city,
						residenceState: formikProps.values.residence.state,
						zip: formikProps.values.residence.zipCode,
					},
					coverageStartMonth: formikProps.values.coverageStartMonth,
					coverageStartYear: formikProps.values.coverageStartYear,
					county: formikProps.values.county,
					householdSize: formikProps.values.householdSize,
					householdAnnualIncome: formikProps.values.householdAnnualIncome,
					deductible: formikProps.values.deductible,
				},
			},
		});
	};

	const residenceState = formikProps.values.residence.state;

	const handlePrev = () => {
		if (activeStep === 7 && formikProps.values.isInsuranceAgent === "No") {
			// skip insurance agent section if applicant is not insurance agent
			setActiveStep(activeStep - 2);
		} else setActiveStep(activeStep - 1);

		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const errorsArr =
		Object.entries(formikProps.errors).length > 0 &&
		objectToArray(formikProps.errors);

	const handleShowErrors = () => {
		setShowErrors(true);
	};

	useEffect(() => {
		setShowErrors(false);
	}, [activeStep]);

	useEffect(() => {
		if (showErrors && scrollRef.current) {
			const elementPosition =
				scrollRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;

			// Scroll to the position with the offset
			window.scrollTo({
				top: elementPosition - offset,
				behavior: "smooth",
			});
		}
	}, [showErrors]);

	useEffect(() => {
		if (state) {
			const { preferredLanguage, isHearingImpaired } = state;

			if (preferredLanguage)
				formikProps.setFieldValue("preferredLanguage", preferredLanguage);

			if (isHearingImpaired)
				formikProps.setFieldValue("isHearingImpaired", isHearingImpaired);
		}
	}, [state]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	const generatePdfBlob = async (
		formValues: typeof medicareFormValues,
		showTimeStamp?: boolean
	) => {
		// Generate the PDF blob using the pdf() function from @react-pdf/renderer
		const pdfDocument = (
			<MedicarePdf formValues={formValues} showTimeStamp={showTimeStamp} />
		);
		const pdfBlob = await pdf(pdfDocument).toBlob();
		return pdfBlob;
	};

	// Callback to handle the generated PDF and trigger the file transfer to the backend
	const handlePdfGenerated = async () => {
		try {
			const pdfBlob = await generatePdfBlob(formikProps.values, true);
			if (!pdfBlob) throw new Error("Error creating pdf blob");

			// Convert PDF Blob to file for uploading
			const formData = new FormData();
			formData.append("pdf", new File([pdfBlob], pdfName));
			formData.append("formType", "MedicareForm");
			formData.append("firstName", formikProps.values.applicant.firstName);
			formData.append("lastName", formikProps.values.applicant.lastName);

			// Trigger backend API call to handle S3 and SFTP
			const response = await axios.post(
				`/api/forms/upload/${formId}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		} catch (error) {
			console.error("Error during file transfer:", error);
		}
	};

	const savePdf = async () => {
		const pdfBlob = await generatePdfBlob(formikProps.values);
		blobToPdf(pdfBlob, pdfName);

		setDownloadPdf(false);
	};

	// save form as pdf when "download pdf" is clicked
	useEffect(() => {
		if (downloadPdf) {
			savePdf();
		}
	}, [downloadPdf]);

	return (
		<>
			{downloadLinkContainer &&
				activeStep !== 0 &&
				createPortal(
					<DownloadPdfButton onClick={() => setDownloadPdf(true)} />,
					downloadLinkContainer
				)}

			{errorsArr && showErrors && (
				<ErrorSummary ref={scrollRef}>
					<h2 className="flex items-center gap-[1rem]">
						<FaExclamationTriangle />
						There was a problem with your submission. Please review the required
						fields below.
					</h2>

					{errorsArr.map((obj, index) => {
						const [[key, value]] = Object.entries(obj);
						return (
							<p>
								{key}: {value}
							</p>
						);
					})}
				</ErrorSummary>
			)}
			<FormikProvider value={formikProps}>
				<div className="flex justify-between items-center gap-[1rem] flex-wrap md:flex-nowrap">
					<h1 className="whitespace-pre-wrap">{steps[activeStep].heading}</h1>
					{!isSubmissionPage && (
						<ProgressIndicator
							activeStep={activeStep}
							totalSteps={steps.length - 2}
						/>
					)}
				</div>

				<FormSeparator />
				{steps[activeStep].subHeading && <p>{steps[activeStep].subHeading}</p>}

				{renderFormStep(activeStep)}
				{!isSubmissionPage && (
					<div className="flex gap-[1rem] mt-[32px]">
						{!isFirstStep && (
							<Button type="button" onClick={handlePrev}>
								<FaArrowLeft />
								Back
							</Button>
						)}

						<Button
							className="font-semibold"
							type="submit"
							disabled={
								formikProps.isSubmitting ||
								(residenceState && activeStep === 1
									? residenceState !== "New Mexico"
									: false)
							}
							onClick={() => {
								handleShowErrors();
								formikProps.submitForm();
							}}
							buttonstyle={
								isLastStep ? ColorStyle.Secondary : ColorStyle.Primary
							}
						>
							{isFirstStep
								? "Start Application"
								: isLastStep && formikProps.values.applyingForLipp === "Yes"
								? "Submit and Start LIPP Application"
								: isLastStep
								? "Submit Application"
								: "Next"}
							<FaArrowRight />
						</Button>
					</div>
				)}
			</FormikProvider>
			<BrokerPopup isOpen={!isSubmissionPage} />

			<Modal
				modalLabel="Money Order or Check Premium Payment"
				open={isMoneyOrderModalOpen}
				setOpen={setIsMoneyOrderModalOpen}
			>
				<NoticeBanner bannerstyle={ColorStyle.Secondary}>
					Your application will be marked as PENDING until your money order or
					check payment of first premium is received.
				</NoticeBanner>
				<Button
					className="mx-auto"
					type="button"
					onClick={() => setIsMoneyOrderModalOpen(false)}
				>
					Continue Application
					<FaArrowRight />
				</Button>
			</Modal>

			{/* {activeStep !== 0 && (
				<div key={String(pdfStyles)}>
					<PDFViewer width="1000" height="1000" showToolbar>
						<MedicarePdf formValues={formikProps.values} />
					</PDFViewer>
				</div>
			)} */}
		</>
	);
};

export default MedicareForm;
