import { FormikProvider, useFormik, useFormikContext } from "formik";
import styled, { css } from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import Button, { ColorStyle } from "../../../components/Button/Button";
import {
	lippFormValues,
	lippFormSchema,
	medicareLippFormSchema,
	medicareLippFormValues,
	schemas,
	medicareCarveOutSchemas,
	Schema,
} from "./formInitialValues";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import {
	steps as nonMedicareSteps,
	medicareCarveOutSteps,
	renderFormStep,
} from "./Steps";
import { borders } from "../../../theme";
import { objectToArray } from "../../../utils/objectToArray";
import { FaExclamationTriangle } from "react-icons/fa";
import BrokerPopup from "../../../components/BrokerPopup/BrokerPopup";
import ProgressIndicator from "../../../components/ProgressIndictaor/ProgressIndicator";
import {
	ErrorSummary,
	FormSeparator,
} from "../../HealthCareCoverageForm/FormSchema/HealthcareForm";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../../components/Header/Header";
import axios from "axios";
import { useFormContext } from "../../../components/FormContext/FormContext";
import { createPortal } from "react-dom";
import DownloadPdfButton from "../../../components/PdfDownload/DownloadPdfButton";
import LIPPPdf from "../Pdf/LIPPPdf";
import { pdfStyles } from "../../../components/PdfDownload/PdfTemplate";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { healthCareFormValues } from "../../HealthCareCoverageForm/FormSchema/formInitialValues";
import HealthCareCoveragePdf from "../../HealthCareCoverageForm/Pdf/HealthCareCoveragePdf";
import { blobToPdf } from "../../../utils/blobToPdf";

const LippForm = () => {
	const { medicare } = useParams();
	const isMedicareCarveOut = medicare === "medicare-carve-out";
	const steps = isMedicareCarveOut ? medicareCarveOutSteps : nonMedicareSteps;
	const [activeStep, setActiveStep] = useState(0);
	const isFirstStep = activeStep === 0;
	const isLastStep = activeStep === steps.length - 2;
	const isSubmissionPage = activeStep === steps.length - 1;
	const [showErrors, setShowErrors] = useState(false);
	const scrollRef = useRef<any>(null);
	const { state } = useLocation();
	const [loading, setLoading] = useState(true);
	const { contactInfo, verificationMethod, formId } = useFormContext();
	const downloadLinkContainer = document.querySelector(
		"#download-link-container"
	);
	const [downloadPdf, setDownloadPdf] = useState(false); // state to trigger PDF download
	const pdfName = `lipp_${
		isMedicareCarveOut ? "medicare" : "non_medicare"
	}.pdf`;

	// Fetch existing form data and merge it with initial values
	useEffect(() => {
		const fetchLippData = async () => {
			try {
				if (formId) {
					const response = await axios.get(`/api/forms/LIPPForm/${formId}`); // Fetch existing LIPP form data
					const fetchedData = response.data;

					// Flatten and merge the fetched data
					const flattenedFetchedData = flattenFetchedData(fetchedData);

					const mergedValues = { ...lippFormValues, ...flattenedFetchedData };

					// Reset form values
					formikProps.resetForm({ values: mergedValues });
				} else console.error("Form Id not available");
			} catch (error) {
				console.error("Failed to fetch LIPP form data:", error);
				setLoading(false);
			}
		};

		// pass values from medicare form
		const setMedicareValues = async () => {
			if (state) {
				const { basicApp }: { basicApp: typeof healthCareFormValues } = state;
				if (basicApp && basicApp.applicant) {
					if (basicApp.applicant.firstName)
						formikProps.setFieldValue(
							"applicant.firstName",
							basicApp.applicant.firstName
						);

					if (basicApp.applicant.lastName)
						formikProps.setFieldValue(
							"applicant.lastName",
							basicApp.applicant.lastName
						);

					if (basicApp.applicant.middleInitial)
						formikProps.setFieldValue(
							"applicant.middleInitial",
							basicApp.applicant.middleInitial
						);

					if (basicApp.applicant.homePhone)
						formikProps.setFieldValue(
							"applicant.homePhone",
							basicApp.applicant.homePhone
						);

					if (basicApp.applicant.workPhone)
						formikProps.setFieldValue(
							"applicant.workPhone",
							basicApp.applicant.workPhone
						);

					if (basicApp.applicant.cellPhone)
						formikProps.setFieldValue(
							"applicant.cellPhone",
							basicApp.applicant.cellPhone
						);

					if (basicApp.applicant.email)
						formikProps.setFieldValue(
							"applicant.email",
							basicApp.applicant.email
						);
				}

				if (basicApp && basicApp.residence) {
					if (basicApp.residence.address)
						formikProps.setFieldValue(
							"residence.address",
							basicApp.residence.address
						);

					if (basicApp.residence.city)
						formikProps.setFieldValue(
							"residence.city",
							basicApp.residence.city
						);

					if (basicApp.residence.state)
						formikProps.setFieldValue(
							"residence.residenceState",
							basicApp.residence.state
						);

					if (basicApp.residence.zipCode)
						formikProps.setFieldValue(
							"residence.zipCode",
							basicApp.residence.zipCode
						);
				}

				if (basicApp) {
					if (basicApp.county)
						formikProps.setFieldValue("county", basicApp.county);

					if (basicApp.householdSize)
						formikProps.setFieldValue("householdSize", basicApp.householdSize);

					if (basicApp.householdAnnualIncome)
						formikProps.setFieldValue(
							"householdAnnualIncome",
							basicApp.householdAnnualIncome
						);
					if (basicApp.deductible)
						formikProps.setFieldValue("deductible", basicApp.deductible);

					if (basicApp.coverageStartMonth)
						formikProps.setFieldValue(
							"coverageStartMonth",
							basicApp.coverageStartMonth
						);

					if (basicApp.coverageStartYear)
						formikProps.setFieldValue(
							"coverageStartYear",
							basicApp.coverageStartYear
						);
				}
			}
		};

		// const fetchHealthcareFormData = new Promise(async () => {
		// 	try {
		// 		const response = await axios.get(
		// 			`/api/forms/HealthCareCoverageForm/${formId}`
		// 		);

		// 		const fetchedData = response.data;
		// 		console.log("Fetched form data on frontend: ", fetchedData);

		// 		// Flatten the fetched data
		// 		const flattenedFetchedData = flattenFetchedData(fetchedData);

		// 		// Update Formik's values by resetting them with the merged values
		// 		formikProps.setValues({
		// 			...formikProps.values,
		// 			...(flattenedFetchedData.applicant
		// 				? { applicant: flattenedFetchedData.applicant }
		// 				: {}),
		// 			...(flattenedFetchedData.residence
		// 				? { residence: flattenedFetchedData.residence }
		// 				: {}),
		// 			...(flattenedFetchedData.county
		// 				? { county: flattenedFetchedData.county }
		// 				: {}),
		// 			...(flattenedFetchedData.householdSize
		// 				? { householdSize: flattenedFetchedData.householdSize }
		// 				: {}),
		// 			...(flattenedFetchedData.householdAnnualIncome
		// 				? {
		// 						householdAnnualIncome:
		// 							flattenedFetchedData.householdAnnualIncome,
		// 				  }
		// 				: {}),
		// 			...(flattenedFetchedData.deductible
		// 				? { deductible: flattenedFetchedData.deductible }
		// 				: {}),
		// 		});
		// 		// setLoading(false);
		// 		return Promise.resolve("success");
		// 	} catch (error) {
		// 		console.error("Failed to fetch basic form data:", error);
		// 		// setLoading(false);
		// 		return Promise.reject(`"Failed to fetch basic form data:", ${error}`);
		// 	}
		// });

		const loadData = async () => {
			await fetchLippData(); // Run this first
			await setMedicareValues(); // Then run this
			setLoading(false);
		};

		loadData();
	}, [formId, state]);

	// Function to flatten nested data (reuse this from the healthcare form logic)
	const flattenFetchedData = (fetchedData: any) => {
		let flattenedData: any = {};
		Object.keys(fetchedData).forEach((schemaKey) => {
			const schemaData = fetchedData[schemaKey];
			if (typeof schemaData === "object" && !Array.isArray(schemaData)) {
				Object.keys(schemaData).forEach((innerKey) => {
					if (typeof schemaData[innerKey] === "object") {
						flattenedData = { ...flattenedData, ...schemaData };
					} else {
						flattenedData[innerKey] = schemaData[innerKey];
					}
				});
			} else {
				flattenedData = { ...flattenedData, ...schemaData };
			}
		});
		return flattenedData;
	};

	// Function to get the relevant section of form data based on the current step
	const getFormSectionByStep = (
		step: number,
		values: any,
		schemas: Record<string, Schema>
	) => {
		const stepName = Object.keys(schemas)[step - 1]; // Get the schema name for the current step

		const schema = schemas[stepName as keyof typeof schemas]; // Find the schema for this step
		if (schema && schema.values) {
			const sectionValues: Record<string, any> = {};

			// Extract only the fields that are part of this schema step
			for (const key in schema.values) {
				if (Object.prototype.hasOwnProperty.call(schema.values, key)) {
					sectionValues[key] = values[key]; // Grab the values from formikProps.values
				}
			}

			return { [stepName]: sectionValues }; // Return the section data under the schema name
		}

		return {}; // Return an empty object if no schema is found
	};

	// Save the current section of the form based on the active step
	const handleSaveForm = async (values: any) => {
		const formSection = getFormSectionByStep(
			activeStep,
			values,
			isMedicareCarveOut ? medicareCarveOutSchemas : schemas
		);

		try {
			await axios.post(`/api/forms/update/LIPPForm/${formId}`, formSection); // Save the current form section
		} catch (error) {
			console.error("Failed to save form data:", error);
		}
	};

	const generatePdfBlob = async (
		formValues: typeof lippFormValues | typeof medicareLippFormValues,
		showTimeStamp?: boolean
	) => {
		// Generate the PDF blob using the pdf() function from @react-pdf/renderer

		const pdfDocument = (
			<LIPPPdf
				formValues={formValues}
				isMedicare={isMedicareCarveOut}
				showTimeStamp={showTimeStamp}
			/>
		);
		const pdfBlob = await pdf(pdfDocument).toBlob();
		return pdfBlob;
	};

	const generateBasicAppBlob = async (
		formValues: typeof healthCareFormValues
	) => {
		// Generate the PDF blob using the pdf() function from @react-pdf/renderer
		const pdfDocument = <HealthCareCoveragePdf formValues={formValues} />;
		const pdfBlob = await pdf(pdfDocument).toBlob();
		return pdfBlob;
	};

	// Callback to handle the generated PDF and trigger the file transfer to the backend
	const handlePdfGenerated = async () => {
		try {
			const pdfBlob = await generatePdfBlob(formikProps.values, true);
			if (!pdfBlob) throw new Error("Error creating pdf blob");

			// Convert PDF Blob to file for uploading
			const formData = new FormData();
			formData.append("pdf", new File([pdfBlob], pdfName));
			formData.append("formType", "LIPPForm");
			formData.append("firstName", formikProps.values.applicant.firstName);
			formData.append("lastName", formikProps.values.applicant.lastName);

			// Trigger backend API call to handle S3 and SFTP
			const response = await axios.post(
				`/api/forms/upload/${formId}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		} catch (error) {
			console.error("Error during file transfer:", error);
		}
	};

	const formikProps = useFormik({
		initialValues: isMedicareCarveOut ? medicareLippFormValues : lippFormValues,
		enableReinitialize: true,
		validationSchema: isMedicareCarveOut
			? medicareLippFormSchema[activeStep]
			: lippFormSchema[activeStep],
		validateOnChange: true,
		onSubmit: async (values, actions) => {
			await handleSaveForm(values); // Save form data

			if (isLastStep) {
				formikProps.setSubmitting(true);
				handlePdfGenerated(); // generate PDF after form is submitted
				setActiveStep(activeStep + 1);
			} else {
				// section 3: Other Income
				if (activeStep === 7) {
					// go to affidavit A
					if (values.filedForFederalTax === "Yes") {
						setActiveStep(8);
					}
					// go to affidavit B
					else if (values.notRequiredToFileFederalTax === "Yes") {
						setActiveStep(9);
					}
					// go to affidavit C
					else if (values.hasIncomeChanged === "Yes") {
						setActiveStep(10);
					}
					// skip all affidavits and go to next page
					else {
						setActiveStep(11);
					}
				}
				// affidavit A
				else if (activeStep === 8) {
					// go to affidavit B
					if (values.notRequiredToFileFederalTax === "Yes") {
						setActiveStep(9);
					}
					// go to affidavit C
					else if (values.hasIncomeChanged === "Yes") {
						setActiveStep(10);
					}
					// skip affidavit B,C and go to next page
					else {
						setActiveStep(11);
					}
				}
				// affidavit B
				else if (activeStep === 9) {
					// go to affidavit C
					if (values.hasIncomeChanged === "Yes") {
						setActiveStep(10);
					}
					// skip affidavit C and go to next page
					else {
						setActiveStep(11);
					}
				} else {
					setActiveStep(activeStep + 1);
				}

				actions.setTouched({});
				actions.setSubmitting(false);
			}

			window.scrollTo({ top: 0, behavior: "smooth" });
		},
	});

	const handlePrev = async () => {
		// Ensure the form section is validated and saved before navigating back
		await formikProps.submitForm();

		// affidavit B
		if (activeStep === 9) {
			// return to affidavit A
			if (formikProps.values.filedForFederalTax === "Yes") {
				setActiveStep(8);
			}
			// skip affidavits and return to section 3
			else {
				setActiveStep(7);
			}
		}
		// affidavit C
		else if (activeStep === 10) {
			// return to affidavit B
			if (formikProps.values.notRequiredToFileFederalTax === "Yes") {
				setActiveStep(9);
			}
			// return to affidavit A
			else if (formikProps.values.filedForFederalTax === "Yes") {
				setActiveStep(8);
			}
			// skip affidavits and return to section 3
			else {
				setActiveStep(7);
			}
		} else setActiveStep(activeStep - 1);

		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const errorsArr =
		Object.entries(formikProps.errors).length > 0 &&
		objectToArray(formikProps.errors);

	const handleShowErrors = () => {
		setShowErrors(true);
	};

	useEffect(() => {
		setShowErrors(false);
	}, [activeStep]);

	useEffect(() => {
		if (showErrors && scrollRef.current) {
			const elementPosition =
				scrollRef.current.getBoundingClientRect().top + window.scrollY;
			const offset = 100;

			// Scroll to the position with the offset
			window.scrollTo({
				top: elementPosition - offset,
				behavior: "smooth",
			});
		}
	}, [showErrors]);

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			event.preventDefault();
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	// useEffect(() => {
	// 	if (state) {
	// 		const { basicApp }: { basicApp: typeof healthCareFormValues } = state;

	// 		if (basicApp && basicApp.applicant) {
	// 			if (basicApp.applicant.firstName)
	// 				formikProps.setFieldValue(
	// 					"applicant.firstName",
	// 					basicApp.applicant.firstName
	// 				);

	// 			if (basicApp.applicant.lastName)
	// 				formikProps.setFieldValue(
	// 					"applicant.lastName",
	// 					basicApp.applicant.lastName
	// 				);

	// 			if (basicApp.applicant.middleInitial)
	// 				formikProps.setFieldValue(
	// 					"applicant.middleInitial",
	// 					basicApp.applicant.middleInitial
	// 				);

	// 			if (basicApp.applicant.homePhone)
	// 				formikProps.setFieldValue(
	// 					"applicant.homePhone",
	// 					basicApp.applicant.homePhone
	// 				);

	// 			if (basicApp.applicant.workPhone)
	// 				formikProps.setFieldValue(
	// 					"applicant.workPhone",
	// 					basicApp.applicant.workPhone
	// 				);

	// 			if (basicApp.applicant.cellPhone)
	// 				formikProps.setFieldValue(
	// 					"applicant.cellPhone",
	// 					basicApp.applicant.cellPhone
	// 				);

	// 			if (basicApp.applicant.email)
	// 				formikProps.setFieldValue(
	// 					"applicant.email",
	// 					basicApp.applicant.email
	// 				);
	// 		}

	// 		if (basicApp && basicApp.residence) {
	// 			if (basicApp.residence.address)
	// 				formikProps.setFieldValue(
	// 					"residence.address",
	// 					basicApp.residence.address
	// 				);

	// 			if (basicApp.residence.city)
	// 				formikProps.setFieldValue("residence.city", basicApp.residence.city);

	// 			if (basicApp.residence.state)
	// 				formikProps.setFieldValue(
	// 					"residence.residenceState",
	// 					basicApp.residence.state
	// 				);

	// 			if (basicApp.residence.zipCode)
	// 				formikProps.setFieldValue(
	// 					"residence.zipCode",
	// 					basicApp.residence.zipCode
	// 				);
	// 		}

	// 		if (basicApp) {
	// 			if (basicApp.county)
	// 				formikProps.setFieldValue("county", basicApp.county);

	// 			if (basicApp.householdSize)
	// 				formikProps.setFieldValue("householdSize", basicApp.householdSize);

	// 			if (basicApp.householdAnnualIncome)
	// 				formikProps.setFieldValue(
	// 					"householdAnnualIncome",
	// 					basicApp.householdAnnualIncome
	// 				);
	// 			if (basicApp.deductible)
	// 				formikProps.setFieldValue("deductible", basicApp.deductible);

	// 			if (basicApp.coverageStartMonth)
	// 				formikProps.setFieldValue(
	// 					"coverageStartMonth",
	// 					basicApp.coverageStartMonth
	// 				);

	// 			if (basicApp.coverageStartYear)
	// 				formikProps.setFieldValue(
	// 					"coverageStartYear",
	// 					basicApp.coverageStartYear
	// 				);
	// 		}
	// 	}
	// }, [state]);

	const savePdf = useCallback(async () => {
		const pdfBlob = await generatePdfBlob(formikProps.values);
		blobToPdf(pdfBlob, pdfName); // download lipp app

		if (state && state.basicApp) {
			const basicAppBlob = await generateBasicAppBlob({
				...healthCareFormValues, // set default values
				...state.basicApp, // set values passed in from basic app
			});
			blobToPdf(basicAppBlob, "application_for_coverage.pdf"); // download basic app
		}

		setDownloadPdf(false);
	}, [state, formikProps.values]);

	// save form as pdf when "download pdf" is clicked
	useEffect(() => {
		if (downloadPdf) {
			savePdf();
		}
	}, [downloadPdf]);

	return (
		<>
			{downloadLinkContainer &&
				activeStep !== 0 &&
				createPortal(
					<DownloadPdfButton onClick={() => setDownloadPdf(true)} />,
					downloadLinkContainer
				)}

			{errorsArr && showErrors && (
				<ErrorSummary ref={scrollRef}>
					<h2 className="flex items-center gap-[1rem]">
						<FaExclamationTriangle />
						There was a problem with your submission. Please review the required
						fields below.
					</h2>

					{errorsArr.map((obj, index) => {
						const [[key, value]] = Object.entries(obj);
						return (
							<p key={index}>
								{key}: {value}
							</p>
						);
					})}
				</ErrorSummary>
			)}
			<FormikProvider value={formikProps}>
				<div className="flex justify-between items-center gap-[1rem] flex-wrap md:flex-nowrap">
					<h1 className="whitespace-pre-wrap">{steps[activeStep].heading}</h1>
					{!isSubmissionPage && (
						<ProgressIndicator
							activeStep={activeStep}
							totalSteps={steps.length - 2}
						/>
					)}
				</div>

				<FormSeparator />
				{steps[activeStep].subHeading && <p>{steps[activeStep].subHeading}</p>}

				{renderFormStep(activeStep, isMedicareCarveOut)}
				{!isSubmissionPage && (
					<div className="flex gap-[1rem] mt-[32px]">
						{!isFirstStep && (
							<Button type="button" onClick={handlePrev}>
								<FaArrowLeft />
								Back
							</Button>
						)}

						<Button
							className="font-semibold"
							type="submit"
							disabled={formikProps.isSubmitting}
							onClick={() => {
								handleShowErrors();
								formikProps.submitForm();
							}}
							buttonstyle={
								isLastStep ? ColorStyle.Secondary : ColorStyle.Primary
							}
						>
							{isFirstStep
								? "Start Application"
								: isLastStep
								? "Submit Application"
								: "Next"}
							<FaArrowRight />
						</Button>
					</div>
				)}
			</FormikProvider>
			<BrokerPopup isOpen={!isSubmissionPage} />
		</>
	);
};

export default LippForm;
